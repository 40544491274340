export default [
  {
    header: 'Báo cáo',
    resource: 'report',
    action: 'read',
  },
  {
    title: 'Báo cáo BĐS',
    route: 'report-property',
    icon: 'BarChart2Icon',
    resource: 'report-property',
    action: 'read',
    children: process.env.VUE_APP_PROVIDER === 'tingtong' ? [
      {
        title: "Phòng gia hạn, chuyển, nhượng",
        route: "report-special-contract",
        resource: 'report-property',
        action: 'read',
      },

      {
        title: "Phòng trống",
        route: "report-empty-room",
        resource: 'report-property',
        action: 'read',
      },
      {
        title: "Phòng sắp trống",
        route: "report-near-empty-room",
        resource: 'report-property',
        action: 'read',
      },
      {
        title: "Tỷ lệ lấp phòng",
        route: "report-percentage-room",
        resource: 'report-property',
        action: 'read',
      },
      {
        title: "Tỷ lệ lấp đầy",
        route: "report-percentage-room-2",
        resource: 'report-property',
        action: 'read',
      },
      {
        title: "Khuyến mại theo hợp đồng",
        route: "report-promotion",
        resource: 'report-property',
        action: 'read',
      },
      {
        title: "Hợp đồng thanh lý bỏ trả",
        route: "report-liquid",
        resource: 'report-property',
        action: 'read',
      }
    ] : [
      {
        title: "Phòng trống",
        route: "report-empty-room",
        resource: 'report-property',
        action: 'read',
      },
      {
        title: "Phòng sắp trống",
        route: "report-near-empty-room",
        resource: 'report-property',
        action: 'read',
      },
      {
        title: "Tỷ lệ lấp phòng",
        route: "report-percentage-room",
        resource: 'report-property',
        action: 'read',
      }
    ],
  },
  {
    title: 'Báo cáo tài chính',
    route: 'report-finance',
    icon: 'PieChartIcon',
    resource: 'report-finance',
    action: 'read',
    children: process.env.VUE_APP_PROVIDER === 'tingtong' ? [
      {
        title: "Công nợ hợp đồng mới",
        route: "report-new-debt",
        resource: 'report-finance',
        action: 'read',
      },
      {
        title: "Khách nợ tiền",
        route: "report-debt",
        resource: 'report-finance',
        action: 'read',
      },
      {
        title: "Lịch thanh toán",
        route: "report-billing-calendar",
        resource: 'report-finance',
        action: 'read',
      },
      {
        title: 'Tiền thừa',
        route: 'prepaid-list',
        icon: 'ShoppingBagIcon',
        resource: 'prepaid',
        action: 'read',
      },
      {
        title: 'Danh sách tiền cọc',
        route: 'report-deposit-tingtong',
        icon: 'ShoppingBagIcon',
        resource: 'report-finance',
        action: 'read',
      },
      {
        title: 'Báo cáo hủy cọc',
        route: 'report-cancel-deposit',
        icon: 'ShoppingBagIcon',
        resource: 'report-finance',
        action: 'read',
      },
      {
        title: 'Thu hồi công nợ',
        route: 'debt-recovery',
        icon: 'ShoppingBagIcon',
        resource: 'report-finance',
        action: 'read',
      },
      {
        title: 'BC check hóa đơn',
        route: 'check-invoice',
        icon: 'ShoppingBagIcon',
        resource: 'report-finance',
        action: 'read',
      },
      {
        title: 'BC check số người',
        route: 'check-tenant',
        icon: 'ShoppingBagIcon',
        resource: 'report-finance',
        action: 'read',
      },
      {
        title: 'BC check điện nước',
        route: 'check-ew',
        icon: 'ShoppingBagIcon',
        resource: 'report-finance',
        action: 'read',
      },
      {
        title: 'BC check dịch vụ',
        route: 'check-fee',
        icon: 'ShoppingBagIcon',
        resource: 'report-finance',
        action: 'read',
      },
    ] : [
      {
        title: "Dòng tiền",
        route: "report-cash-flow",
        resource: 'report-finance',
        action: 'read',
      },
      // {
      //   title: "Dòng tiền dự kiến",
      //   route: "report-finance-forecast",
      //   resource: 'report-finance',
      //   action: 'read',
      // },
      {
        title: "Phân bổ lợi nhuận",
        route: "report-finance-by-month",
        resource: 'report-finance',
        action: 'read',
      },
      {
        title: "Khách nợ tiền",
        route: "report-debt",
        resource: 'report-finance',
        action: 'read',
      },
      {
        title: "Danh sách tiền cọc",
        route: "report-finance-deposit",
        resource: 'report-finance',
        action: 'read',
      },
      {
        title: "Lịch thanh toán",
        route: "report-billing-calendar",
        resource: 'report-finance',
        action: 'read',
      },
      {
        title: 'Tiền thừa',
        route: 'prepaid-list',
        icon: 'ShoppingBagIcon',
        resource: 'prepaid',
        action: 'read',
      },
    ],
  },
  // {
  //   title: 'BC Khách hàng',
  //   route: 'report-tenant',
  //   icon: 'TvIcon',
  // },
  // {
  //   title: 'BC Công việc',
  //   route: 'report-task',
  //   icon: 'UmbrellaIcon',
  // },
  // {
  //   title: 'BC Tài sản',
  //   route: 'report-asset',
  //   icon: 'UmbrellaIcon',
  // },
];
